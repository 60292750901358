import { useClub } from 'src/context/useClub';
import DOMPurify from 'dompurify';
import css from './index.module.scss';
import cx from 'classnames';
import React from 'react';
import { Card } from 'src/shared/widgets/Card';

const LEADERSHIP_TEAM = [
  {
    name: 'Bob Bhania',
    role: 'Chair',
    imageUrl: `https://ui-avatars.com/api/?name=${'Bob Bhania'}`,
  },
  {
    name: 'Ian Cheeseman',
    role: 'Treasurer',
    imageUrl: `https://ui-avatars.com/api/?name=${'Ian Cheeseman'}`,
  },
  {
    name: 'Steph Strachan',
    role: 'Safeguarding and Welfare Officer',
    imageUrl: `https://ui-avatars.com/api/?name=${'Steph Strachan'}`,
  },
  {
    name: 'Adam Sullivan',
    role: 'Club Liaison',
    imageUrl: `https://ui-avatars.com/api/?name=${'Adam Sullivan'}`,
  },
  {
    name: 'Fran Edwards',
    role: 'Table Officials’ Mentor',
    imageUrl: `https://ui-avatars.com/api/?name=${'Fran Edwards'}`,
  },
  {
    name: 'Clare Holt',
    role: 'Division 1 Secretary',
    imageUrl: `https://ui-avatars.com/api/?name=${'Clare and Paul Holt'}`,
  },
  {
    name: 'Paul Holt',
    role: 'Division 2 Secretary',
    imageUrl: `https://ui-avatars.com/api/?name=${'Paul Holt'}`,
  },
  {
    name: 'Sotos Frantzanas',
    role: 'Division 2 Secretary',
    imageUrl: `https://ui-avatars.com/api/?name=${'Sotos Frantzanas'}`,
  },
  {
    name: 'Andrew Carey',
    role: 'Disciplinary Secretary',
    imageUrl: `https://ui-avatars.com/api/?name=${'Andrew Carey'}`,
  },
  {
    name: 'Peter Thorp',
    role: 'Constitution and Policies',
    imageUrl: `https://ui-avatars.com/api/?name=${'Peter Thorp'}`,
  },
  {
    name: 'Ray McHugh',
    role: 'Referees’ Mentor',
    imageUrl: `https://ui-avatars.com/api/?name=${'Ray McHugh'}`,
  },
];

const LeadershipCard = ({ name, role, imageUrl }: { name: string; role: string; imageUrl: string }) => (
  <Card>
    <div className='d-flex align-items-center'>
      <img
        src={imageUrl}
        alt={name}
        style={{
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          marginRight: '15px',
        }}
      />
      <div>
        <p className='mb-1 ts-fs-18'>{role}</p>
        <h5 className='mb-0 ts-fw-700 ts-fs-18'>{name}</h5>
      </div>
    </div>
  </Card>
);

interface ResumeItemProps {
  title: string;
  value: number;
  withDivider?: boolean;
}

const ResumeItem: React.FC<ResumeItemProps> = ({ title, value, withDivider = true }) => (
  <>
    <div className='d-flex flex-column align-items-center justify-content-center'>
      <p className='ts-fw-500 fs-4 mb-1'>{value}</p>
      <p className='text-center fs-6' style={{ whiteSpace: 'nowrap' }}>
        {title}
      </p>
    </div>
    {withDivider && (
      <div
        style={{
          width: '1px',
          height: '3.5rem',
          backgroundColor: '#bbc3d3',
          margin: '0 20px',
        }}
      />
    )}
  </>
);

const SEASON_STATS = [
  { title: 'D1 teams', value: 9 },
  { title: 'D2 teams', value: 10 },
  { title: 'Referees', value: 50 },
  { title: 'Fixtures', value: 450 },
  { title: 'Ref bookings', value: 90 },
  { title: 'D1 players', value: 60 },
  { title: 'D2 players', value: 80 },
  { title: 'Coaches', value: 30 },
];

export default function AboutPage() {
  const {
    club: { info },
  } = useClub();

  const sanitizedHtml = DOMPurify.sanitize(info.about);
  const sanitizedHistoryHtml = DOMPurify.sanitize(info.history);

  return (
    <div>
      <div className='bg-white'>
        <div className='container-fluid container-lg py-5 '>
          <div className='row align-items-center'>
            <div className='col-12 col-md-6'>
              <div className='pe-md-4'>
                <h3 className='mb-2 ts-color-tertiary'>WELCOME</h3>
                <h4 className='mb-4 ts-fw-900'>{info.name}</h4>
                <div className={cx('mb-4', css.paragraph16)} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
              </div>
            </div>

            <div className='col-12 col-md-6 mt-4 mt-md-0'>
              <div
                className='rounded-3 ratio ratio-16x9'
                style={{
                  backgroundImage: `url(${info.aboutImageUrl || info.logoUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            </div>
          </div>

          {info.history && (
            <div className='row align-items-center mt-5'>
              <div className='col-12 col-md-6 mt-4 mt-md-0'>
                <div
                  className='rounded-3 ratio ratio-16x9'
                  style={{
                    backgroundImage: `url(${info.historyImageUrl || info.logoUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                />
              </div>

              <div className='col-12 col-md-6'>
                <div className='pe-md-4'>
                  <h4 className='mb-4 '>Our History</h4>
                  <div
                    className={cx('mb-4', css.paragraph16)}
                    dangerouslySetInnerHTML={{ __html: sanitizedHistoryHtml }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div>
        <div className='container-fluid container-lg py-5'>
          <h3 className='mb-4'>Leadership</h3>
          <div className='row g-4'>
            {LEADERSHIP_TEAM.map(leader => (
              <div key={leader.name} className='col-12 col-md-6 col-lg-4'>
                <LeadershipCard {...leader} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='bg-white'>
        <div className='container-fluid container-lg py-5'>
          <h3 className='mb-4'>This season stats</h3>
          <div className='d-flex justify-content-between align-items-center overflow-auto'>
            {SEASON_STATS.map((stat, index) => (
              <ResumeItem
                key={stat.title}
                title={stat.title}
                value={stat.value}
                withDivider={index !== SEASON_STATS.length - 1}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
